// Current Company Colors
$main-color: #2a5696;
$secondary-color: #b3b3b3;
$button-glow-color: #2a5696;
$button-color: #2a5696;
$button-text-color: white;

// Stealth Colors
$stealth-blue: #233c90;
$stealth-white: #ffffff;
$stealth-black: #07031a;
$stealth-lightblue: #2cfefd;
$stealth-turquoise: #20acb7;
//$stealth-darkgray: #414549;
$stealth-darkgray: #4d4d4d;
//$stealth-lightgray: #c4c4c4;
$stealth-lightgray: #e5e5e5;
$steatlh-yellow: #f6bb42;

// Component Colors
//  - Darkmode
$background-color-darkmode: #212529;
$footer-header-color-darkmode: #252a2f;
//  - Lightmode
$background-color-lightmode: #ebedef;
$footer-header-color-lightmode: $stealth-white;
$metrics-background-color: rgba(121, 125, 131, 0.2);

// Nav Bar
$nav-color-hover: $main-color;
$nav-color: $secondary-color;

// Fonts
$body-font: "Avenir Next LT Pro", sans-serif;
$main-font-color-darkmode: $stealth-white;
$main-font-color-lightmode: $stealth-black;

$theme-colors__dt: (
  // "success": $green,
  // "warning": $yellow,
);
